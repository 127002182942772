<template>
  <div>
    <template v-if="windowWidth < 768">
      <el-container>
        <el-header style="margin: 0 !important;padding: 0px !important;">
          <HeadBar></HeadBar>
        </el-header>
        <el-main style="margin: 0 !important;padding: 10px !important;">
          <router-view />
        </el-main>
        <el-footer style="margin: 0 !important;padding: 0 !important;">
          <BottomBar />
        </el-footer>
      </el-container>
    </template>


    <template v-else>
      <el-container>
        <el-aside :width="isCollapsed ? '40px' : '120px'" class="custom-aside">
          <Sidebar :isCollapsed="isCollapsed" @toggle-collapse="toggleCollapse" />
        </el-aside>
        <el-container :style="{ marginLeft: isCollapsed ? '40px' : '120px' }" class="main-container">
          <el-main class="main-content">
            <HeadBar></HeadBar>
            <router-view />
          </el-main>
        </el-container>
      </el-container>
    </template>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount,inject } from 'vue';
import Sidebar from './Sidebar.vue';
import HeadBar from './HeadBar.vue';
import BottomBar from './BottomBar.vue'; // Import the new component
const infos=inject('infos');


const windowWidth = ref(window.innerWidth);
const isCollapsed = ref(false);

function toggleCollapse() {
  isCollapsed.value = !isCollapsed.value;
}

const updateWindowWidth = () => {
  windowWidth.value = window.innerWidth;
};

onMounted(() => {
  window.addEventListener('resize', updateWindowWidth);
  updateWindowWidth(); // Initialize window width check
  if (window.innerWidth <= 768) {
    isCollapsed.value = true;
  }
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateWindowWidth);
});
</script>

<style scoped>
.custom-aside {
  position: fixed;
  height: 100%;
  overflow: hidden;
  transition: width 0.3s;
  border-right: 1px solid #eaeaea;
  display: flex;
  flex-direction: column;
}

.main-container {
  transition: margin-left 0.3s;
}

.main-content {
  height: 100vh;
  overflow-y: auto;
  padding: 5px;
}
</style>
