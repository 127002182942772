<template>
  <div>
    <div class="collapse-icon" @click="toggleCollapse">
      <el-icon :size="20">
        <template v-if="isCollapsed">
          <Expand />
        </template>
        <template v-else>
          <Fold />
        </template>
      </el-icon>
    </div>
    <el-menu
      :default-openeds="['1']"
      :default-active="activePath"
      class="el-menu-vertical-demo"
      background-color="white"
      text-color="#000"
      active-text-color="#ffd04b"
      :collapse="isCollapsed"
      :collapse-transition="false"
    >
      <el-submenu index="1">
        <template #title>
          <span>导航栏</span>
        </template>
        <el-menu-item index="/list" @click="navigateTo('/list')">
          <el-tooltip content="账号列表" placement="right" v-if="isCollapsed">
            <el-icon class="menu-icon"><List /></el-icon>
          </el-tooltip>
          <el-icon class="menu-icon" v-else><List /></el-icon>
          <span class="menu-text" :class="{ hidden: isCollapsed }">账号列表</span>
        </el-menu-item>

        <el-menu-item v-if="!isHide" index="/mall" @click="navigateTo('/mall')">
          <el-tooltip content="在线商城" placement="right" v-if="isCollapsed">
            <el-icon class="menu-icon"><Shop /></el-icon>
          </el-tooltip>
          <el-icon class="menu-icon" v-else><Shop /></el-icon>
          <span class="menu-text" :class="{ hidden: isCollapsed }">在线商城</span>
        </el-menu-item>


        <!-- <el-menu-item index="/promotion" @click="navigateTo('/promotion')">
          <el-tooltip content="我的推广" placement="right" v-if="isCollapsed">
            <el-icon class="menu-icon"><Promotion /></el-icon>
          </el-tooltip>
          <el-icon class="menu-icon" v-else><Promotion /></el-icon>
          <span class="menu-text" :class="{ hidden: isCollapsed }">我的推广</span>
        </el-menu-item> -->

        <el-menu-item index="/me" @click="navigateTo('/me')">
          <el-tooltip content="个人中心" placement="right" v-if="isCollapsed">
            <el-icon class="menu-icon"><UserFilled /></el-icon>
          </el-tooltip>
          <el-icon class="menu-icon" v-else><UserFilled /></el-icon>
          <span class="menu-text" :class="{ hidden: isCollapsed }">个人中心</span>
        </el-menu-item>
      </el-submenu>
    </el-menu>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { Fold, Expand, List, Shop, Promotion, UserFilled } from '@element-plus/icons-vue';
import {isHide} from './store.js'
const emit = defineEmits(['toggle-collapse']);

const props = defineProps({
  isCollapsed: {
    type: Boolean,
    required: true
  }
});

const router = useRouter();
const route = useRoute();

const activePath = computed(() => route.path);

function toggleCollapse() {
  emit('toggle-collapse');
}

function navigateTo(path) {
  router.push(path);
}
</script>

<style scoped>
.custom-aside {
  height: 100vh;
  display: flex;
  flex-direction: column;
  transition: all 0.3s;
  overflow-x: hidden;
}

.collapse-icon {
  margin: 10px;
  cursor: pointer;
}

.el-menu-vertical-demo {
  width: 100%;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  transition: all 0.3s;
}

.menu-icon {
  margin-left: 0;
}

.menu-text {
  margin-left: 0;
  transition: opacity 0.3s;
  opacity: 1;
}

.menu-text.hidden {
  opacity: 0;
}

.el-menu-item {
  padding-left: 8px !important;
}

.el-menu {
  border-right: 0 !important;
}
</style>
