// store.js
import { reactive, ref } from 'vue'
import axios from 'axios'

export const account = reactive({})
export const isLoggedIn = ref(false)
export const infos = reactive({})
export const hostname=window.location.hostname;
export const isHide=(hostname=='www.chatgpt-claude.asia'||hostname=='chatgpt-claude.asia')

export function setAccount(data) {
  Object.assign(account, data)
}

export function clearAccount() {
  Object.keys(account).forEach(key => delete account[key])
}


export function autoLogin() {
  return new Promise((resolve, reject) => {
    const loginToken = localStorage.getItem('loginToken');
    if (loginToken) {
      axios.post('/job/autoLogin', { 'loginToken': loginToken })
        .then(response => {
          if (response.data.status === 200) {
            Object.assign(account, response.data); // 将整个响应数据赋值给account
            isLoggedIn.value = true;
          } else {
            isLoggedIn.value = false;
          }
          resolve(); // 无论是否成功登录，都解析Promise
        })
        .catch(error => {
          console.log(error);
          isLoggedIn.value = false;
          resolve(); // 捕获错误，解析Promise以继续导航
        });
    } else {
      resolve(); // 没有token时，直接解析Promise以继续导航
    }
  });
}

// 获取通知数据
export function fetchInfos(){
  axios.get('/job/info', {})
    .then(response => {
      Object.assign(infos, response.data)
    })
    .catch(error => {
      console.log(error);
    });
}

