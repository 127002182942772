<template>
  <el-header class="header-bar">
    <div class="header-left">
      <el-text type="primary" id="title-text"></el-text>
    </div>
    <div class="header-right">
      <template v-if="!isLoggedIn">
        <el-button @click="openDialog">登录</el-button>
      </template>
      <template v-else>
        <el-popover placement="bottom" width="260" trigger="hover">
          <el-tag style="width:100%;margin-bottom:5px;" type="success">@<el-link type="success">{{ account.phone
              }}</el-link></el-tag>
          <template
            v-if="account.UserTokenPlus && !isExpired(account.UserTokenPlus.expireTime) && !account.UserTokenPlus.deleteAt">
            <el-tag style="width:100%;margin-bottom:5px;" type="success">PLUS会员-{{
              countTime(account.UserTokenPlus.expireTime) }}</el-tag>
          </template>
          <template v-else>
            <el-tag style="width:100%;margin-bottom:5px;" type="success">普通会员-{{
              countTime(account.UserTokenNormal.expireTime) }}</el-tag>
          </template>
          <el-button style="width:100%;margin-bottom:5px;" type="danger" size="small"
            @click="handleLogout">退出登录</el-button>
          <template #reference>
            <el-avatar class="custom-avatar" shape="square">{{ account.UserTokenPlus &&
              !isExpired(account.UserTokenPlus.expireTime) && !account.UserTokenPlus.deleteAt ? 'PLUS会员' : '普通会员'
              }}</el-avatar>
          </template>
        </el-popover>
      </template>
    </div>
  </el-header>

  <el-dialog v-model="dialogVisible" :width="dialogWidth" :before-close="handleClose" :append-to-body="true">
    <el-tabs v-model="activeTab">
      <el-tab-pane label="登录" name="login">
        <div>
          <div class="form-item">
            <el-input id="login-phone" v-model="loginForm.phone" placeholder="手机号" autocomplete="off" v-slot:prepend>
              <el-icon>
                <User />
              </el-icon>
            </el-input>
          </div>
          <div class="form-item">
            <el-input id="login-password" v-model="loginForm.password" type="password" placeholder="密码" autocomplete="new-password" v-slot:prepend @input="filterNonEnglish">
              <el-icon>
                <Lock />
              </el-icon>
            </el-input>
          </div>
          <div class="form-item form-item-center">
            <el-button type="primary" @click="handleLogin">登录</el-button>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="注册" name="register">
        <div>
          <div class="form-item phone-item">
            <el-input id="register-phone" v-model="registerForm.phone" placeholder="手机号" autocomplete="off">
              <template #prepend>
                <el-icon>
                  <User />
                </el-icon>
              </template>
              <template #append>
                <el-button @click="sendRegisterCode" :disabled="countdown > 0">
                  {{ countdown > 0 ? `${countdown}秒后重试` : '发送验证码' }}
                </el-button>
              </template>
            </el-input>
          </div>
          <div class="form-item">
            <el-input id="register-password" v-model="registerForm.password" type="password" placeholder="密码" autocomplete="new-password" inputmode="english" v-slot:prepend @input="filterNonEnglish">
              <el-icon>
                <Lock />
              </el-icon>
            </el-input>
          </div>
          <div class="form-item">
            <el-input id="register-confirm-password" v-model="registerForm.confirmPassword" type="password" placeholder="确认密码" autocomplete="new-password" inputmode="english" v-slot:prepend @input="filterNonEnglish">
              <el-icon>
                <Lock />
              </el-icon>
            </el-input>
          </div>
          <div class="form-item">
            <el-input id="register-checkCode" v-model="registerForm.checkCode" placeholder="验证码" autocomplete="off" v-slot:prepend>
              <el-icon>
                <Checked />
              </el-icon>
            </el-input>
          </div>
          <div class="form-item">
            <el-input id="register-inviteCode" v-model="registerForm.inviteCode" placeholder="邀请码(选填)" autocomplete="off" v-slot:prepend @input="formatInviteCode">
              <el-icon>
                <Key />
              </el-icon>
            </el-input>
          </div>
          <div class="form-item form-item-center">
            <el-button type="primary" @click="handleRegister">注册</el-button>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="找回密码" name="findPassword">
        <div>
          <div class="form-item phone-item">
            <el-input id="findPassword-phone" v-model="findPasswordForm.phone" placeholder="手机号" autocomplete="off">
              <template #prepend>
                <el-icon>
                  <User />
                </el-icon>
              </template>
              <template #append>
                <el-button @click="sendFindPasswordCode" :disabled="countdown > 0">
                  {{ countdown > 0 ? `${countdown}秒后重试` : '发送验证码' }}
                </el-button>
              </template>
            </el-input>
          </div>
          <div class="form-item">
            <el-input id="findPassword-checkCode" v-model="findPasswordForm.checkCode" placeholder="验证码" autocomplete="off" v-slot:prepend>
              <el-icon>
                <Checked />
              </el-icon>
            </el-input>
          </div>
          <div class="form-item">
            <el-input id="findPassword-password" v-model="findPasswordForm.password" type="password" placeholder="新密码" autocomplete="new-password" inputmode="english" v-slot:prepend @input="filterNonEnglish">
              <el-icon>
                <Lock />
              </el-icon>
            </el-input>
          </div>
          <div class="form-item form-item-center">
            <el-button type="primary" @click="handleChangePassword">提交</el-button>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </el-dialog>
  <el-divider></el-divider>
</template>

<script setup>
import TypeIt from 'typeit'
import { ref, reactive, onMounted, onBeforeUnmount, onBeforeMount } from 'vue'
import { ElMessage } from 'element-plus'
import axios from 'axios'
import { User, Lock, Checked, Key } from '@element-plus/icons-vue'
import Cookies from 'js-cookie';
import { isExpired, countTime } from './util'

const dialogVisible = ref(false)
const activeTab = ref('login')
const dialogWidth = ref('500px')
import { account, setAccount, clearAccount, autoLogin, isLoggedIn,isHide } from './store'

const loginForm = reactive({
  phone: '',
  password: ''
})

const registerForm = reactive({
  phone: '',
  password: '',
  confirmPassword: '',
  checkCode: '',
  inviteCode: ''
})

const findPasswordForm = reactive({
  phone: '',
  password: '',
  checkCode: ''
})

// Countdown related data and methods
const countdown = ref(0);
const countdownInterval = ref(null);

function openDialog() {
  dialogVisible.value = true;
}

function handleClose() {
  dialogVisible.value = false;
}

function isValidPhone(phone) {
  const phonePattern = /^1[3-9]\d{9}$/
  return phonePattern.test(phone)
}

const startCountdown = () => {
  countdown.value = 60;
  localStorage.setItem('countdownEndTime', Date.now() + countdown.value * 1000);
  countdownInterval.value = setInterval(updateCountdown, 1000);
};

const updateCountdown = () => {
  const endTime = localStorage.getItem('countdownEndTime');
  const remainingTime = Math.floor((endTime - Date.now()) / 1000);

  if (remainingTime > 0) {
    countdown.value = remainingTime;
  } else {
    clearInterval(countdownInterval.value);
    countdown.value = 0;
  }
};

const initializeCountdown = () => {
  const endTime = localStorage.getItem('countdownEndTime');
  if (endTime) {
    const remainingTime = Math.floor((endTime - Date.now()) / 1000);
    if (remainingTime > 0) {
      countdown.value = remainingTime;
      countdownInterval.value = setInterval(updateCountdown, 1000);
    } else {
      localStorage.removeItem('countdownEndTime');
    }
  }
};

function validateLoginForm() {
  if (!loginForm.phone || !isValidPhone(loginForm.phone)) {
    ElMessage({
      message: '请输入有效的手机号',
      grouping: true,
      type: 'error',
    });
    return false;
  }
  if (!loginForm.password) {
    ElMessage({
      message: '请输入密码',
      grouping: true,
      type: 'error',
    });
    return false;
  }
  return true;
}

function validateRegisterForm() {
  if (!registerForm.phone || !isValidPhone(registerForm.phone)) {
    ElMessage({
      message: '请输入有效的手机号',
      grouping: true,
      type: 'error',
    });
    return false;
  }
  if (!registerForm.checkCode) {
    ElMessage({
      message: '请输入验证码',
      grouping: true,
      type: 'error',
    });
    return false;
  }
  if (!registerForm.password) {
    ElMessage({
      message: '请输入密码',
      grouping: true,
      type: 'error',
    });
    return false;
  }
  if (registerForm.password !== registerForm.confirmPassword) {
    ElMessage.error('密码和确认密码不一致');
    return false;
  }
  return true;
}

function validateFindPasswordForm() {
  if (!findPasswordForm.phone || !isValidPhone(findPasswordForm.phone)) {
    ElMessage({
      message: '请输入有效的手机号',
      grouping: true,
      type: 'error',
    });
    return false;
  }
  if (!findPasswordForm.checkCode) {
    ElMessage({
      message: '请输入验证码',
      grouping: true,
      type: 'error',
    });
    return false;
  }
  if (!findPasswordForm.password) {
    ElMessage({
      message: '请输入新密码',
      grouping: true,
      type: 'error',
    });
    return false;
  }
  return true;
}

function sendRegisterCode() {
  if (!registerForm.phone || !isValidPhone(registerForm.phone)) {
    ElMessage({
      message: '请输入有效的手机号',
      grouping: true,
      type: 'error',
    })
    return;
  }

  axios.post('/job/sendRegisterPhoneCode', { "phone": registerForm.phone })
    .then(response => {
      if (response.data.code != 'OK') {
        ElMessage({
          message: response.data.message,
          grouping: true,
          type: 'error',
        })
        return;
      }

      ElMessage.success('发送成功');
      startCountdown();
    })
    .catch(error => {
      console.error('Error:', error);
      ElMessage({
          message: '验证码发送失败，请进群反馈',
          grouping: true,
          type: 'error',
        })
    });
}

function sendFindPasswordCode() {
  if (!findPasswordForm.phone || !isValidPhone(findPasswordForm.phone)) {
    ElMessage({
      message: '请输入有效的手机号',
      grouping: true,
      type: 'error',
    })
    return;
  }

  axios.post('/job/sendFindPasswordCheckCode', { "phone": findPasswordForm.phone })
    .then(response => {
      if (response.data.code != 'OK') {
        ElMessage({
          message: response.data.message,
          grouping: true,
          type: 'error',
        })
        return;
      }

      ElMessage.success('发送成功');
      startCountdown();
    })
    .catch(error => {
      console.error('Error:', error);
      ElMessage({
          message: '验证码发送失败，请进群反馈',
          grouping: true,
          type: 'error',
        })
    });
}

function handleLogin() {
  if (!validateLoginForm()) {
    return;
  }
  axios.post('/job/login', {
    phone: loginForm.phone,
    password: loginForm.password
  }).then(response => {
    if (response.data.status === 200) {
      dialogVisible.value = false;
      localStorage.setItem('loginToken', response.data.loginToken); // 保存登录令牌
      Object.assign(account, response.data); // 将整个响应数据赋值给account
      isLoggedIn.value = true;
      ElMessage.success('登录成功');
    } else {
      ElMessage({
          message: response.data.message,
          grouping: true,
          type: 'error',
        })
    }
  }).catch((error) => {
    console.log(error);
    ElMessage({
          message: '登录失败，请进群反馈',
          grouping: true,
          type: 'error',
        })
  });
}

function handleRegister() {
  if (!validateRegisterForm()) {
    return;
  }

  axios.post('/job/register', {
    phone: registerForm.phone,
    password: registerForm.password,
    checkCode: registerForm.checkCode,
    inviteCode: registerForm.inviteCode,
    isGive: isHide? null:'yes'
  }).then(response => {
    if (response.data.code === '200') {
      ElMessage.success('注册成功! 请前往个人中心激活PLUS会员');
      dialogVisible.value = false;

      loginForm.phone = registerForm.phone;
      loginForm.password = registerForm.password;
      handleLogin();

    } else {
      ElMessage({
          message: response.data.message,
          grouping: true,
          type: 'error',
        })
    }
  })
    .catch(error => {
      ElMessage({
          message: '注册失败，请进群反馈',
          grouping: true,
          type: 'error',
        })
      console.log(error);
    });
}

function handleChangePassword() {
  if (!validateFindPasswordForm()) {
    return;
  }

  axios.post('/job/changePassword', {
    phone: findPasswordForm.phone,
    newPassword: findPasswordForm.password,
    checkCode: findPasswordForm.checkCode
  }).then(response => {
    if (response.data.code === '200') {
      ElMessage.success('密码修改成功');
      dialogVisible.value = false;
    } else {
      ElMessage({
          message: response.data.message,
          grouping: true,
          type: 'error',
        })
    }
  }).catch(error => {
    ElMessage({
          message: '密码修改失败，请进群反馈',
          grouping: true,
          type: 'error',
        })
    console.log(error);
  });
}

function handleLogout() {
  localStorage.removeItem('loginToken');
  Object.keys(account).forEach(key => delete account[key]);
  Cookies.remove('gfsessionid');
  isLoggedIn.value = false;
  ElMessage.success('已退出登录');
}

function checkViewport() {
  if (window.innerWidth <= 768) {
    dialogWidth.value = '100%';
  } else {
    dialogWidth.value = '500px';
  }
}

function formatInviteCode() {
  registerForm.inviteCode = registerForm.inviteCode.toUpperCase().replace(/[^A-Z0-9]/g, '');
}

function filterNonEnglish(event) {
  event.target.value = event.target.value.replace(/[^a-zA-Z0-9]/g, '');
}

onBeforeMount(() => {
  autoLogin(); // 在组件挂载时尝试自动登录
  initializeCountdown(); // 初始化倒计时
});

onMounted(() => {
  checkViewport();
  window.addEventListener('resize', checkViewport);

  new TypeIt("#title-text", {
    speed: 100,
    waitUntilVisible: true,
    cursor: false,
  })
    .pause(300)
    .type("OpenAI中国代理", { delay: 1000 })
    .go();
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', checkViewport);
});
</script>


<style scoped>
.header-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  border-radius: 10px;
  height: 50px;
  margin-bottom: 0;
}

.header-left {
  display: flex;
  align-items: center;
}

#title-text {
  border: none;
  color: #2c3e50;
  font-weight: bold;
  font-size: 30px;
  padding: 0;
}

.header-right {
  display: flex;
  align-items: center;
}

.el-dialog__body {
  padding: 20px;
}

.form-item {
  margin-bottom: 20px;
}

.form-item-center {
  display: flex;
  justify-content: center;
}

.custom-input .el-input__inner {
  border-radius: 20px !important;
  background-color: #f5f5f5 !important;
  border: none !important;
}

.custom-input .el-input__icon {
  color: #ccc !important;
}

.phone-item {
  display: flex;
  align-items: center;
}

.phone-item .el-input {
  flex-grow: 1;
}

.send-code-button {
  margin-left: 10px;
}

.el-divider {
  margin-top: 0;
  margin-bottom: 20px;
}

.custom-avatar {
  background-color: lightpink;
}
</style>
