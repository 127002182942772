import { createApp } from 'vue';
import App from './App.vue';
import router from '../../router';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import axios from 'axios';
import {infos,fetchInfos} from '../../components/frontend/store';
import 'animate.css/animate.min.css'


// 开发环境配置的后端服务提供的接口
if( process.env.NODE_ENV === 'development')
{
  axios = axios.create({
    baseURL: "http://45.152.64.36:6778",
    timeout: 100000
  });
}

fetchInfos();

const app = createApp(App);

// 注册所有的 Element Plus 图标组件
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}


// 将axios添加到全局属性
app.config.globalProperties.$axios = axios;
app.config.warnHandler = () => null;
app.use(router)
   .use(ElementPlus)
   .mount('#app');
